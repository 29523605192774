import React from "react";
import { useGame } from "../../../../context/game";
import modeSelectBgImg from "../../../../images/modeSelectBg.png";
import Button from "../../Button";
import Modal from "../Base";

interface Props {
  open: boolean;
  center?: boolean;
  blockScroll?: boolean;
  closeIcon?: React.ReactNode;
  classNames?: {
    overlay?: string;
    modal?: string;
    closeButton?: string;
    closeIcon?: string;
    animationIn?: string;
    animationOut?: string;
  };
  styles?: {
    overlay?: React.CSSProperties;
    modal?: React.CSSProperties;
    closeButton?: React.CSSProperties;
    closeIcon?: React.CSSProperties;
  };
  onClose: () => void;
  onEscKeyDown?: (event: KeyboardEvent) => void;
  onOverlayClick?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  onAnimationEnd?: () => void;
  onSubmit: any;
}

const DifficultyModal: React.FC<Props> = ({
  open,
  onClose,
  closeIcon,
  classNames,
  styles,
  onSubmit,
}) => {
  const { setDifficulty, difficulty } = useGame();

  return (
    <Modal
      open={open}
      title={`Game Mode`}
      onOverlayClick={onClose}
      classNames={{
        ...classNames,
        modal: "border-2 border-black rounded-lg mx-auto shadow-lg",
      }}
      styles={{
        ...styles,
        overlay: { padding: "1rem" },
        modal: { maxWidth: 532, minWidth: "unset", width: "100%" },
      }}
    >
      <div
        className="relative bg-center bg-top bg-contain bg-no-repeat bg-black max-h-72 sm:bg-cover text-center flex items-center 
        justify-center rounded-lg rounded-t-none sm:max-h-full w-full overflow-hidden"
      >
        <img
          src={modeSelectBgImg}
          alt="Game Mode"
          style={{
            width: "532px",
            maxWidth: "100%",
          }}
          className="relative"
        />
        <div className="absolute">
          <span className="inline-flex rounded-md shadow-sm mr-4 sm:mr-20">
            <Button
              onClick={() => {
                setDifficulty(0);
                onSubmit();
              }}
              type="button"
              text="Young Hero"
              color={difficulty === 0 ? "#7356e5" : "#23233f"}
              classNames={{ text: "text-sm p-0 md:text-lg md:p-2" }}
            />
          </span>
          <span className="inline-flex rounded-md shadow-sm ml-4 sm:ml-20">
            <Button
              onClick={() => {
                setDifficulty(1);
                onSubmit();
              }}
              type="button"
              color={difficulty === 1 ? "#7356e5" : "#23233f"}
              text="Hero"
              classNames={{ text: "text-sm p-0 md:text-lg md:p-2" }}
            />
          </span>
        </div>

        <div className="text-sm p-4 absolute bottom-0 mx-auto bg-gray-700 bg-opacity-75 text-gray-300 rounded-lg mb-8">
          <p>
            <span className="font-semibold">Young Hero</span> - Name of the
            cards
          </p>
          <p>
            <span className="font-semibold">Hero</span> - Card names and stats
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default DifficultyModal;
